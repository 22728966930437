import React from "react";
import {
  Typography,
  Box,
  TextField,
  Button,
  Snackbar,
  Grid,
} from "@mui/material";
import "./App.css";
import axios from "axios";

const App = () => {
  const [email, setEmail] = React.useState("");
  const [open, setOpen] = React.useState(false);
  return (
    <div className="App">
      <img src="/png1.png" width="300" alt="Artisan Cabinetry" />

      <Typography
        variant="h5"
        color={"white"}
        component={"span"}
        position="relative"
        left="35px"
        bottom="18px"
      >
        Artisan Cabinetry LLC
      </Typography>
      <Box sx={{ width: 430, margin: "auto", marginTop: 24 }}>
        <Typography
          color="white"
          variant="h6"
          textAlign={"center"}
          marginBottom={2}
        >
          Our website is under construction. We'll be here soon, subscribe to be
          notified
        </Typography>
        <TextField
          variant="outlined"
          value={email}
          sx={{
            backgroundColor: "#a2a2a2",
            color: "white",
            borderRadius: 1,
            borderColor: "white",
            width: 250,
          }}
          size="small"
          outlined={false}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          InputProps={{
            style: { borderColor: "white" },
            classes: {
              notchedOutline: "custom-outlined-input-border",
            },
          }}
          inputProps={{
            style: { color: "white" },
          }}
          placeholder="Enter your email"
        />
        <Snackbar
          open={open}
          onClose={() => setOpen(false)}
          message="You have been subscribed successfully!"
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        />
        <Button
          variant="contained"
          color="primary"
          size="large"
          sx={{ marginLeft: 2 }}
          onClick={() => {
            console.log(email);
            setEmail("");
            setOpen(true);
            axios.post(
              "http://construction-1112841983.us-east-1.elb.amazonaws.com/subscribe",
              {
                email,
                businessName: "Artisan Cabinetry",
              }
            );
          }}
        >
          Subscribe
        </Button>
      </Box>
      <Box sx={{ marginTop: 16 }}>
        <Box sx={{ width: 430, margin: "auto" }}>
          <Typography variant="h5" textAlign={"center"}>
            Products
          </Typography>
        </Box>
        <Grid container spacing={2} sx={{ width: 760, margin: "auto"}}>
          {["Shaker Gray", "Shaker White", "Navy Blue", "Slim White Oak", "Slim Dove White", "High Gloss Gray", "High Gloss White"].map((product) => (
            <Grid item xs={6}>
              <img src={`/${product}.jpg`} alt={product} width={350}/>
              <div style={{ textAlign: 'center', marginTop: '8px' }}>{product}</div>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box
        sx={{
          marginTop: 12,
          width: "100%",
        }}
      >
        <Box sx={{ width: 430, margin: "auto" }}>
          <Typography variant="h5" textAlign={"center"}>
            Address
          </Typography>
          <Typography variant="body1" textAlign={"center"}>
            10000 W Sam Houston PKWY N, Houston, TX 77064
          </Typography>
          <Typography variant="body1" textAlign={"center"}>
            Phone number: (850) 524-4260
          </Typography>
        </Box>
      </Box>
    </div>
  );
};

export default App;
